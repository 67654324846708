import * as React from 'react'
import Layout from '../components/layout'
import { title } from '../components/my-module.css'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { divCenter, cardCat, txtlight, txtBlack, hpmain } from '../components/layout.module.css'
import cat_bracelet from '../images/prodcat/cat_bracelet.png';
import cat_charms from '../images/prodcat/cat_charms.png';
import cat_coasters from '../images/prodcat/cat_coasters.png';
import cat_earrings from '../images/prodcat/cat_earrings.png';
import cat_fridge_magnets from '../images/prodcat/cat_fridge_magnets.png';
import cat_hair_clip from '../images/prodcat/cat_hair_clip.png';
import cat_necklaces from '../images/prodcat/cat_necklaces.png';
import cat_ornaments from '../images/prodcat/cat_ornaments.png';
import cat_tray from '../images/prodcat/cat_tray.png';

const ProductsPage = () => {
  return (
    <Layout pageTitle="Crafts">
      <div className={hpmain}>

        <div className={divCenter}>

          <Link to="/favors">
            <div className={cardCat}>
              <article>
                <h3 className={txtBlack}>
                  Μπομπονιέρες
                </h3>
              </article>
            </div>
          </Link>

          <Link to="/jewelry">
            <div className={cardCat}>
              <article>
                <h3 className={txtBlack}>
                  Κοσμήματα
                </h3>
              </article>
            </div>
          </Link>

          <Link to="/holders">
            <div className={cardCat}>
              <article>
                <h3 className={txtBlack}>
                  Βάσεις
                </h3>
              </article>
            </div>
          </Link>



        </div>

        <div className={divCenter}>

          <Link to="/coasters">
            <div className={cardCat}>
              <article>
                <h3 className={txtBlack}>
                  Σουβέρ
                </h3>
              </article>
            </div>
          </Link>

          <Link to="/trays">
            <div className={cardCat}>
              <article>
                <h3 className={txtBlack}>
                  Δίσκοι
                </h3>
              </article>
            </div>
          </Link>

          <Link to="/ashtrays">
            <div className={cardCat}>
              <article>
                <h3 className={txtBlack}>
                  Τασάκια
                </h3>
              </article>
            </div>
          </Link>



        </div>

        {/* 

        <div className={divCenter}>

          <Link to="/ornaments">
            <div className={cardCat}>
              <article>
                <h3 className={txtBlack}>
                  Στολίδια
                </h3>
              </article>
            </div>
          </Link>

          <Link to="/charms">
            <div className={cardCat}>
              <article>
                <h3 className={txtBlack}>
                  Γούρια
                </h3>
              </article>
            </div>
          </Link>


        </div>
      */}

      </div>

    </Layout>
  )
}

export default ProductsPage
